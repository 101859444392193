





























import { Component, Vue } from 'vue-property-decorator'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class TyreHotelInventoryFront extends Vue {
  appendSitePrefix(url) {
    return appendSitePrefix(url)
  }
}
